<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Container's Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
          <div class="col-md-10">
            <input ref="txtname" type="text" class="form-control" placeholder="Enter Name here..." maxlength="100" v-if="cont" v-model="cont.name" required autofocus >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Type:</label>
          <div class="col-md-10">
            <select class="form-control" v-if="cont" v-model="cont.type"  >
              <option value="1">Cone</option>
              <option value="2">Babin</option>
              <option value="3">Beam</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Weight:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter empty Weight here..." v-if="cont" v-model="cont.base_weight">
          </div>
        </div>


    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>
  import FabButton from '@/components/core/FabButton.vue'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'ContainerForm',
    data () {
      return {
        cont: JSON.parse('{"id":0,"status":1,"type":1,"name":"","item_id":1,"base_weight":0,"weight":0}'),
        processes: []
      }
    },
    props: {
      mycont: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":1,"type":1,"name":"","item_id":1,"base_weight":0,"weight":0}')
      }
    },
    beforeMount () {
      this.cont = this.mycont; // save props data to itself's data and deal with tms
    },
    component: {
      FabButton
    },
    created () {
      this.$data.cont = JSON.parse('{"id":0,"status":1,"type":1,"name":"","item_id":1,"base_weight":0,"weight":0}')
    },
    mounted () {

      this.$refs.txtname.focus();
    },
    methods: {
      closeWindow() {
        // this.$parent.$parent.closeModal();
        this.$emit('container_window_closed');
      },
      saveDocument(){
        let self = this;

        self.$data.cont.type = parseInt(self.$data.cont.type);
        self.$data.cont.base_weight = parseFloat(self.$data.cont.base_weight);

        if(self.$data.cont.name.trim().length < 1){
          alert("Invalid Name");
          return
        }else if(self.$data.cont.type < 1){
          alert("Invalid Container Type");
          return
        }else if(self.$data.cont.base_weight <= 0){
          alert("Invalid Weight");
          return
        }


        const requestOptions = {
          method:  (self.$data.cont.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.cont)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}api/container`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.ok) {
            swal ( { title: "Success!" ,  text: data.msg, type:  "success", timer: 1000} );
            self.cont = JSON.parse('{"id":0,"status":1,"type":1,"name":"","item_id":1,"base_weight":0,"weight":0}');
            self.$emit('container_saved');
          } else {
            swal ( { title: "Oops!" ,  text: data.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
